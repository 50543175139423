import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  Icon,
  Label,
  Loader,
  Message,
  Table
} from "semantic-ui-react";
import moment from './utils/momentfr';

class Firmwares extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firmwares: undefined,
      configurators: undefined,
      devApks: undefined,
      error: undefined
    };
  }

  componentDidMount() {
    this.getFirmwares();
    this.getConfigurators();
    this.getDevApks();
  }

  getFirmwares() {
    return this.props.rest().get('firmwares').then((firmwares) => {
      this.setState({
        firmwares
      });
    }).catch((e) => {
      this.manageErrors(e);
    });
  }

  getConfigurators() {
    return this.props.rest().get('configurators').then((configurators) => {
      this.setState({
        configurators
      });
    }).catch((e) => {
      this.manageErrors(e);
    });
  }

  getDevApks() {
    return this.props.rest().get('devApks').then((devApks) => {
      this.setState({
        devApks
      });
    }).catch((e) => {
      this.manageErrors(e);
    });
  }

  onDownloadFirmware(filename) {
    // Not working on dev, probably because of proxying
    return (event) => {
      const pathWithAuth = this.props.rest().withAuth(`firmwares/download/${filename}`);
      window.open(pathWithAuth, filename, "menubar=no, status=no");
    }
  }

  onDownloadConfigurator(filename) {
    // Not working on dev, probably because of proxying
    return (event) => {
      const pathWithAuth = this.props.rest().withAuth(`configurators/download/${filename}`);
      window.open(pathWithAuth, filename, "menubar=no, status=no");
    }
  }

  onDownloadDevApk(filename) {
    // Not working on dev, probably because of proxying
    return (event) => {
      const pathWithAuth = this.props.rest().withAuth(`devApks/download/${filename}`);
      window.open(pathWithAuth, filename, "menubar=no, status=no");
    }
  }

  manageErrors(err) {
    if(err !== undefined) {
      this.setState({
        error: err.entity
      });
      setTimeout(() => {
        this.setState({
          error: undefined
        });
      }, 5000);
    }
  }

  // TODO refactoring : merge configurators and firmwares
  render() {
    return (
        <Container className="adminContentClass" textAlign="center">
            <Grid padded>
              <Grid.Row>
                {
                  this.state.error !== undefined ?
                    <Message negative content={this.state.error} />
                    : ""
                }
              </Grid.Row>
              <Grid.Row style={{
                  backgroundColor: '#545454',
                  borderRadius: '10px'
                }}>
                <Grid.Column width={2}>
                  <Icon name='hdd' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={10} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Liste des firmwares pour Raspberry Pi
                      <Header.Subheader>
                        Les liens contiennent votre token de session. <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>NE LES PARTAGEZ JAMAIS !</span>
                      </Header.Subheader>
                    </Header.Content>  
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}></Grid.Column>
              </Grid.Row>
              <Grid.Row style={{minHeight: '30vh'}}>
                {
                  this.state.firmwares === undefined ?
                  <Dimmer inverted active>
                    <Loader inverted />
                  </Dimmer>                
                  :
                  <Table singleLine striped selectable sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Fichier</Table.HeaderCell>
                        <Table.HeaderCell>Taille</Table.HeaderCell>
                        <Table.HeaderCell>Dernier changement</Table.HeaderCell>
                        <Table.HeaderCell>md5sum</Table.HeaderCell>
                        <Table.HeaderCell>sha1sum</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        this.state.firmwares.map((f, idx) => (
                          <Table.Row key={`details_firmware_${idx}`}>
                            <Table.Cell>{f.filename}</Table.Cell>
                            <Table.Cell>{`${f.sizeInGB} GB`}</Table.Cell>
                            <Table.Cell>{`${moment.unix(f.lastChange).format("DD/MM/YYYY HH:mm:ss")}`}</Table.Cell>
                            <Table.Cell>{f.md5sum}</Table.Cell>
                            <Table.Cell>{f.sha1sum}</Table.Cell>
                            <Table.Cell><Button onClick={this.onDownloadFirmware(f.filename).bind(this)} icon color='red'><Icon name='download' /></Button></Table.Cell>
                          </Table.Row>
                        ))
                      }
                    </Table.Body>
                  </Table>
                }
              </Grid.Row>
              <Grid.Row style={{
                  backgroundColor: '#545454',
                  borderRadius: '10px'
                }}>
                <Grid.Column width={2}>
                  <Icon name='configure' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={10} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Liste des versions du Configurator
                      <Header.Subheader>
                        Les liens contiennent votre token de session. <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>NE LES PARTAGEZ JAMAIS !</span>
                      </Header.Subheader>
                    </Header.Content>  
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}></Grid.Column>
              </Grid.Row>
              <Grid.Row style={{minHeight: '30vh'}}>
                {
                  this.state.configurators === undefined ?
                  <Dimmer inverted active>
                    <Loader inverted />
                  </Dimmer>                
                  :
                  <Table singleLine striped selectable sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Fichier</Table.HeaderCell>
                        <Table.HeaderCell>Taille</Table.HeaderCell>
                        <Table.HeaderCell>Dernier changement</Table.HeaderCell>
                        <Table.HeaderCell>md5sum</Table.HeaderCell>
                        <Table.HeaderCell>sha1sum</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        this.state.configurators.map((f, idx) => (
                          <Table.Row key={`details_configurator_${idx}`}>
                            <Table.Cell>{f.filename}</Table.Cell>
                            <Table.Cell>{`${f.sizeInGB} GB`}</Table.Cell>
                            <Table.Cell>{`${moment.unix(f.lastChange).format("DD/MM/YYYY HH:mm:ss")}`}</Table.Cell>
                            <Table.Cell>{f.md5sum}</Table.Cell>
                            <Table.Cell>{f.sha1sum}</Table.Cell>
                            <Table.Cell><Button onClick={this.onDownloadConfigurator(f.filename).bind(this)} icon color='red'><Icon name='download' /></Button></Table.Cell>
                          </Table.Row>
                        ))
                      }
                    </Table.Body>
                  </Table>
                }
              </Grid.Row>
              <Grid.Row style={{
                  backgroundColor: '#545454',
                  borderRadius: '10px'
                }}>
                <Grid.Column width={2}>
                  <Icon name='mobile alternate' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={10} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Liste des APK Android de dev
                      <Header.Subheader>
                        Les liens contiennent votre token de session. <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>NE LES PARTAGEZ JAMAIS !</span>
                      </Header.Subheader>
                    </Header.Content>  
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}></Grid.Column>
              </Grid.Row>
              <Grid.Row style={{minHeight: '30vh'}}>
                {
                  this.state.devApks === undefined ?
                  <Dimmer inverted active>
                    <Loader inverted />
                  </Dimmer>                
                  :
                  <Table singleLine striped selectable sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Fichier</Table.HeaderCell>
                        <Table.HeaderCell>Taille</Table.HeaderCell>
                        <Table.HeaderCell>Dernier changement</Table.HeaderCell>
                        <Table.HeaderCell>md5sum</Table.HeaderCell>
                        <Table.HeaderCell>sha1sum</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        this.state.devApks.map((f, idx) => (
                          <Table.Row key={`details_devApks_${idx}`}>
                            <Table.Cell>{f.filename}</Table.Cell>
                            <Table.Cell>{`${f.sizeInGB} GB`}</Table.Cell>
                            <Table.Cell>{`${moment.unix(f.lastChange).format("DD/MM/YYYY HH:mm:ss")}`}</Table.Cell>
                            <Table.Cell>{f.md5sum}</Table.Cell>
                            <Table.Cell>{f.sha1sum}</Table.Cell>
                            <Table.Cell><Button onClick={this.onDownloadDevApk(f.filename).bind(this)} icon color='red'><Icon name='download' /></Button></Table.Cell>
                          </Table.Row>
                        ))
                      }
                    </Table.Body>
                  </Table>
                }
              </Grid.Row>
            </Grid>
        </Container>
    );
  }
}

export default Firmwares;
