import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Container,
  Dimmer,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Message,
  Table
} from "semantic-ui-react";
import resolvePath from 'object-resolve-path';

class SerialNumbers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      certList: [],
      loading: true,
      sortState: {
        direction: 'ascending',
        column: 'commonName'
      },
      error: undefined,
      megaFilter: "",
      selectedStatusFilter: []
    };
  }

  componentDidMount() {
    this.getSerialNumbers();
  }

  getSerialNumbers() {
    return this.props.rest().get('openvpn/certList').then((certList) => {
      this.setState({
        certList
      });
    }).catch((e) => {
      this.manageErrors(e);
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  manageErrors(err) {
    if(err !== undefined) {
      this.setState({
        error: err.entity
      });
      setTimeout(() => {
        this.setState({
          error: undefined
        });
      }, 5000);
    }
  }

  handleSort(clickedColumn) {
    return () => {
      const { column, direction } = this.state.sortState;
      const data = this.state.phoneHardwares;

      if (column !== clickedColumn) {
        this.setState({
          sortState: Object.assign(this.state.sortState, {
            column: clickedColumn,
            direction: 'ascending'
          }),
          phoneHardwares: data.sort((d1,d2) => resolvePath(d1, clickedColumn) < resolvePath(d2, clickedColumn) ? -1 : 1)
        });
        return;
      }

      this.setState({
        sortState: Object.assign(this.state.sortState, {
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
          column
        })
      });
    }
  }

  render() {
    const statuses = [
      {
        key: "V",
        text: "Valide",
        value: "V"
      },
      {
        key: "R",
        text: "Révoqué",
        value: "R"
      }
    ];

    const formatRSADate = (dateStr) => {
      // yymmddHHMMSSZ (Z = Zulu = UTC)
      // See https://groups.google.com/g/mailing.openssl.users/c/gMRbePiuwV0
      // 2 digits for year...wtf !?
      const year = dateStr.slice(0, 2);
      const month = dateStr.slice(2, 4);
      const day = dateStr.slice(4, 6);
      const hour = dateStr.slice(6, 8);
      const minute = dateStr.slice(8, 10);
      const second = dateStr.slice(10, 12);
      return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    };

    const filteredSerialNumbers = this.state.certList
    .filter(x => this.state.selectedStatusFilter.length > 0 ? this.state.selectedStatusFilter.includes(x.validity) : true)
    .filter(x => this.state.megaFilter.length > 0 ?
      x.commonName.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      x.fingerprint.toLowerCase().includes(this.state.megaFilter.toLowerCase())
    : true);

    return (
        <Container className="adminContentClass" textAlign="center">
            <Grid padded>
              <Grid.Row>
                {
                  this.state.error !== undefined ?
                    <Message negative content={this.state.error} />
                    : ""
                }
              </Grid.Row>
              <Grid.Row style={{
                  backgroundColor: '#545454',
                  borderRadius: '10px'
                }}>
                <Grid.Column width={2}>
                  <Icon name='numbered list' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={10} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Liste des numéros de série
                      <Header.Subheader>
                        Retrouvez ici la liste des certificats connus par le VPN
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                  <Button disabled data-tooltip="Ajouter un numéro de série" data-position="bottom right" icon color='green'>
                    <Icon name='plus' />
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Input
                    placeholder='Recherche libre'
                    icon='search'
                    fluid
                    onChange={(evt) => this.setState({megaFilter: evt.target.value})}
                    value={this.state.megaFilter}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Dropdown
                    placeholder='Tous les statuts'
                    search
                    fluid
                    selection
                    multiple
                    clearable
                    onChange={(e, data) => this.setState({selectedStatusFilter: data.value})}
                    value={this.state.selectedStatusFilter}
                    options={statuses}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign: 'left'}} width={2}>
                  <Label size='large'>{filteredSerialNumbers.length} ligne(s) trouvée(s)</Label>
                </Grid.Column>
                <Grid.Column width={14}></Grid.Column>
              </Grid.Row>
              <Grid.Row style={{minHeight: '30vh'}}>
                {
                  this.state.loading === true ?
                  <Dimmer inverted active>
                    <Loader inverted />
                  </Dimmer>                
                  :
                  <Table striped selectable sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'commonName' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('commonName').bind(this)}>
                          Nom
                        </Table.HeaderCell>
                        <Table.HeaderCell>Empreinte</Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState.column === 'validity' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('validity').bind(this)}>
                          Validité
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Date d'expiration
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Date de révocation
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        filteredSerialNumbers
                        .map((sn, idx) => (
                          <Table.Row key={`details_serialno_${idx}`}>
                            <Table.Cell width={2}>{sn.commonName.slice(4)}</Table.Cell>
                            <Table.Cell>{sn.fingerprint}</Table.Cell>
                            <Table.Cell width={1}>{sn.validity} <Icon name={sn.validity === 'V' ? 'check' : 'delete'} color={sn.validity === 'V' ? 'green' : 'red'} /></Table.Cell>
                            <Table.Cell>{formatRSADate(sn.expirationDate)}</Table.Cell>
                            <Table.Cell>{sn.revocationDate !== '' ? formatRSADate(sn.revocationDate) : ''}</Table.Cell>
                          </Table.Row>
                        ))
                      }
                    </Table.Body>
                  </Table>
                }
              </Grid.Row>
            </Grid>
        </Container>
    );
  }
}

export default SerialNumbers;
