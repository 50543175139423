const STATUSES = [
    {
      key: "PRODUCTION",
      text: "En prod",
      value: "PRODUCTION"
    },
    {
      key: "IN_STOCK",
      text: "En stock",
      value: "IN_STOCK"
    },
    {
      key: "DISMANTLED",
      text: "Démantelée",
      value: "DISMANTLED"
    },
    {
      key: "SAV",
      text: "En SAV",
      value: "SAV"
    },
    {
      key: "OTHER",
      text: "Autre",
      value: "OTHER"
    }
  ];

  const STATUSES_WITHOUT_PROD = STATUSES.filter(x => x.key !== "PRODUCTION");

  const COMMERCIAL_STATUSES = [
    {
      key: "RENT",
      text: "Loué",
      value: "RENT"
    },
    {
      key: "SOLD",
      text: "Vendu",
      value: "SOLD"
    }
  ];

  const DATA_STICKS = [
    {
      key: "HUAWEI",
      text: "HUAWEI E172",
      value: "HUAWEI"
    },
    {
      key: "EC25",
      text: "EC25",
      value: "EC25"
    },
    {
      key: "EC25-HEX",
      text: "EC25 HEX",
      value: "EC25-HEX"
    },
    {
      key: "NONE",
      text: "N/A",
      value: "NONE"
    }
  ];

  const BOARDS = [
    {
      key: "RPI3B",
      text: "3B",
      value: "RPI3B"
    },
    {
      key: "RPI3BP",
      text: "3B+",
      value: "RPI3BP"
    },
    {
      key: "ODROID_C4",
      text: "C4",
      value: "ODROID_C4"
    }
  ];

  const RFIDS = [
    {
      key: "125KHZ",
      text: "125KHZ",
      value: "125KHZ"
    },
    {
      key: "13MHZ",
      text: "13MHZ",
      value: "13MHZ"
    }
  ];

  export {
    STATUSES,
    COMMERCIAL_STATUSES,
    STATUSES_WITHOUT_PROD,
    DATA_STICKS,
    BOARDS,
    RFIDS
  };