import React, { Fragment, Component } from "react";
import "semantic-ui-css/semantic.min.css";
import { Route, Switch, Redirect, Link, withRouter } from "react-router-dom";
import Customers from './Customers';
import Dashboard from './Dashboard';
import Login from './Login';
import RpiHardwares from './RpiHardwares';
import Storage from './Storage';
import SerialNumbers from './SerialNumbers';
import PhoneHardwares from './PhoneHardwares';
import Firmwares from './Firmwares';
import { Button, Container, Grid, Icon, Image, Label, Menu } from "semantic-ui-react";
import "./App.css";
import rest from './utils/rest';
import ls from 'local-storage';
import Logout from "./Logout";

class App extends Component {
  state = {
    loggedIn: false,
    rest: rest(undefined, undefined, this.props.history),
    dropdownMenuStyle: {
      display: "none"
    },
    wantedTabOnLoad: this.props.history.location.pathname && this.props.history.location.pathname !== '/login' ? this.props.history.location.pathname : '/'
  };

  handleToggleDropdownMenu = () => {
    let newState = Object.assign({}, this.state);
    if (newState.dropdownMenuStyle.display === "none") {
      newState.dropdownMenuStyle = { display: "flex" };
    } else {
      newState.dropdownMenuStyle = { display: "none" };
    }

    this.setState(newState);
  };

  refreshToken(username, token) {
    this.setState({rest: rest(username, token, this.props.history), loggedIn: username !== undefined && token !== undefined}, () => {
      if(token === undefined) {
        ls.remove('token');
      } else {
        ls('token', token);
      }
      if(username === undefined) {
        ls.remove('username');
      } else {
        ls('username', username);
      }
      //this.props.history.push('/');
    });
  }

  getLS(str) {
    return ls(str);
  }

  cleanLS() {
    ls.remove('token');
    ls.remove('username');
  }

  handleLogout() {
    this.refreshToken(undefined, undefined);
    this.cleanLS();
    this.setState({
      rest: rest(undefined, undefined, this.props.history),
      loggedIn: false,
      dismissedGreenMsg: false
    });
    this.props.history.replace('/');
  }

  getRest() {
    return this.state.rest;
  }

  isProd() {
    return window.location.hostname === 'admin.orane.online';
  }

  isLoggedIn() {
    return this.state.loggedIn;
  }

  handleDismiss(event) {
    this.setState({dismissedGreenMsg: true});
  }

  render() {
    const PrivateRoute = (props) => (
      <Fragment>
          { this.isLoggedIn() ? props.children : <Redirect to='/login' /> }
      </Fragment>
    )

    return (
      <Switch>
      <Route path="/login" exact>
        <Login to={this.state.wantedTabOnLoad} loggedIn={this.state.loggedIn} rest={this.getRest.bind(this)} getLS={this.getLS.bind(this)} cleanLS={this.cleanLS.bind(this)} refreshToken={this.refreshToken.bind(this)} />
      </Route>
      <PrivateRoute>
      <div className="App">
        <Grid padded className="tablet computer only">
          <Menu borderless fluid inverted size="huge">
            <Container className="adminContentClass">
              <Menu.Item header as="span">
                <Image inline size='tiny' id="logo" src='/logo.png' />
              </Menu.Item>

              <Menu.Item header as="span">
                ADMINISTRATION
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/'} as="span">
                <Link to="/">
                  <Icon inverted name='line graph' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/settings'} as="span">
                <Link to="/settings">
                  <Icon inverted name='settings' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/rpihardwares'} as="span">
                <Link to="/rpihardwares">
                  <Icon inverted name='hdd' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/serialnumbers'} as="span">
                <Link to="/serialnumbers">
                  <Icon inverted name='numbered list' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/phonehardwares'} as="span">
                <Link to="/phonehardwares">
                  <Icon inverted name='mobile alternate' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item as="span">
                <a href="https://tracking.timefast.fr" target="_blank">
                  <Icon inverted name='point' size='big' />
                </a>
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/firmwares'} as="span">
                <Link to="/firmwares">
                  <Icon inverted name='microsoft' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/storage'} as="span">
                <Link to="/storage">
                  <Icon inverted name='box' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item as="span">
                {
                  this.state.dismissedGreenMsg ? null :
                  <Label size="big" color={this.isProd() ? "red" : "green"}>
                    Environnement : {this.isProd() ? "production" : "développement"}
                    {/*!this.isProd() ? <Icon name='close' onClick={this.handleDismiss.bind(this)} /> : null*/}
                  </Label>
                }
              </Menu.Item>
              <Menu.Item as="span" position="right">
                <Button
                  icon
                  inverted
                  basic
                  onClick={this.handleLogout.bind(this)}
                >
                  <Icon name="sign out" />
                </Button>
              </Menu.Item>
            </Container>
          </Menu>
        </Grid>
        <Grid padded className="mobile only">
          <Menu borderless fluid inverted size="huge">
            <Menu.Item header as="span">
                <Image inline size='mini' src='/logo.png' />
            </Menu.Item>
            <Menu.Item header as="span">
              ADMINISTRATION
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>
                <Button
                  icon
                  inverted
                  basic
                  toggle
                  onClick={this.handleToggleDropdownMenu}
                >
                  <Icon name="content" />
                </Button>
              </Menu.Item>
            </Menu.Menu>
            <Menu
              borderless
              fluid
              inverted
              vertical
              style={this.state.dropdownMenuStyle}
            >
              <Menu.Item active={this.props.location.pathname === '/'} as="span">
                <Link to="/">
                  <Icon inverted name='line graph' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/settings'} as="span">
                <Link to="/settings">
                  <Icon inverted name='settings' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/rpihardwares'} as="span">
                <Link to="/rpihardwares">
                  <Icon inverted name='hdd' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/serialnumbers'} as="span">
                <Link to="/serialnumbers">
                  <Icon inverted name='numbered list' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/phonehardwares'} as="span">
                <Link to="/phonehardwares">
                  <Icon inverted name='mobile alternate' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item as="span">
                <a href="https://tracking.timefast.fr" target="_blank">
                  <Icon inverted name='point' size='big' />
                </a>
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/firmwares'} as="span">
                <Link to="/firmwares">
                  <Icon inverted name='microsoft' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item active={this.props.location.pathname === '/storage'} as="span">
                <Link to="/storage">
                  <Icon inverted name='box' size='big' />
                </Link>
              </Menu.Item>
              <Menu.Item as="span" position="right">
                <Button
                  icon
                  inverted
                  basic
                  onClick={this.handleLogout.bind(this)}
                >
                  <Icon name="sign out" />
                </Button>
              </Menu.Item>
            </Menu>
          </Menu>
        </Grid>
        <Switch>
          <Route path="/" exact>
            <Dashboard rest={this.getRest.bind(this)} />
          </Route>
          <Route path="/settings" exact>
            <Customers rest={this.getRest.bind(this)} />
          </Route>
          <Route path="/rpihardwares" exact>
            <RpiHardwares rest={this.getRest.bind(this)} />
          </Route>
          <Route path="/serialnumbers" exact>
            <SerialNumbers rest={this.getRest.bind(this)} />
          </Route>
          <Route path="/phonehardwares" exact>
            <PhoneHardwares rest={this.getRest.bind(this)} />
          </Route>
          <Route path="/firmwares" exact>
            <Firmwares rest={this.getRest.bind(this)} />
          </Route>
          <Route path="/storage" exact>
            <Storage rest={this.getRest.bind(this)} />
          </Route>
          <Route path="/logout" exact>
            <Logout handleLogout={this.handleLogout.bind(this)} />
          </Route>
        </Switch>
      </div>
      </PrivateRoute>
      </Switch>
    );
  }
}

export default withRouter(App);