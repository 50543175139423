import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Grid,
  Header,
  Label,
  Loader,
} from "semantic-ui-react";
import Plot from 'react-plotly.js';
import * as localeDictionary from 'plotly.js/lib/locales/fr.js'
import moment from './utils/momentfr';

class CustomerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rpiHardwareOnline: [],
      loading: false
    };
  }

  componentDidMount() {
    this.setState({loading: true}, () => {
      this.props.rest().get(`rpih-online-history/${this.props.customer.id}`).then((rpiHardwareOnline) => {
        this.setState({
          rpiHardwareOnline
        });
      }).catch(e => console.log(JSON.stringify(e))).finally(() => {
        this.setState({loading: false});
      });
    });
  }

  render() {
    console.log(this.state.rpiHardwareOnline);
    if(this.props.customer.history !== undefined) {
      const {employees, profiles, pointages} = this.props.customer.history;
      const rpiHardwareOnline = this.state.rpiHardwareOnline;
      const rpiHardware = Object.keys(this.props.customer.rpiHardware || {});
      return (
          <Grid padded style={{width: '100%'}}>
            <Grid.Row></Grid.Row>
            <Grid.Row style={{
                  backgroundColor: '#646464',
                  borderRadius: '10px'
              }}>
              <Grid.Column width={1}>
                <Button onClick={this.props.close} icon='backward' data-position="bottom left" data-tooltip="Revenir en arrière" color='red' style={{float: 'left'}} />
              </Grid.Column>
              <Grid.Column width={4}></Grid.Column>
              <Grid.Column width={6} verticalAlign="middle" textAlign="center">
                  <Header size="large" as="h2" inverted>
                    <Header.Content>
                      {`Détails du client ${this.props.customer.name}`}
                    </Header.Content>  
                  </Header>
              </Grid.Column>
              <Grid.Column width={5}></Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} textAlign="center" verticalAlign="middle">
                <Label color='teal' size='big'>Nombre d'employés</Label>
              </Grid.Column>
              <Grid.Column width={12} textAlign="center" verticalAlign="middle">
                <Plot
                  data={[
                    {
                      x: employees.map(e => moment.unix(e.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                      y: employees.map(e => e.nbEmployees),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: 'teal',
                        size: 8
                      },
                      line: {
                        color: 'teal',
                        width: 2
                      },
                      name: 'Total'
                    },
                    {
                      x: employees.map(e => moment.unix(e.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                      y: employees.map(e => e.nbEmployeesActive),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: 'blue',
                        size: 8
                      },
                      line: {
                        color: 'blue',
                        width: 2,
                        dash: 'dashdot'
                      },
                      name: 'Actifs'
                    }
                  ]}
                  layout={ {
                    width: '100%',
                    title: '',
                    xaxis: {
                      title: '',
                      type: 'date',
                      autorange: true,
                      rangeslider: {range: [
                        moment.unix(Math.min(...employees.map(e => e.timestamp))).format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD")
                      ]},
                    },
                    yaxis: {
                      title: ''
                    }
                  } }
                  config={{displayModeBar: true, locale: 'fr', locales: { 'fr': localeDictionary }}}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} textAlign="center" verticalAlign="middle">
                <Label color='olive' size='big'>Nombre de profils</Label>
              </Grid.Column>
              <Grid.Column width={12} textAlign="center" verticalAlign="middle">
                <Plot
                  data={[
                    {
                      x: profiles.map(p => moment.unix(p.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                      y: profiles.map(p => p.nbProfiles),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: 'olive',
                        size: 8
                      },
                      line: {
                        color: 'olive',
                        width: 2
                      },
                      name: 'Total'
                    }
                  ]}
                  layout={ {
                    width: '100%',
                    title: '',
                    xaxis: {
                      type: 'date',
                      autorange: true,
                      rangeslider: {range: [
                        moment.unix(Math.min(...profiles.map(p => p.timestamp))).format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD")
                      ]}
                    }
                  } }
                  config={{displayModeBar: true, locale: 'fr', locales: { 'fr': localeDictionary }}}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} textAlign="center" verticalAlign="middle">
                <Label color='purple' size='big'>Nombre de pointages</Label>
              </Grid.Column>
              <Grid.Column width={12} textAlign="center" verticalAlign="middle">
                <Plot
                  data={[
                    {
                      x: pointages.map(p => moment.unix(p.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                      y: pointages.map(p => p.nbPointages),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: 'purple',
                        size: 8
                      },
                      line: {
                        color: 'purple',
                        width: 2
                      },
                      name: 'Pointages standards'
                    },
                    {
                      x: pointages.map(p => moment.unix(p.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                      y: pointages.map(p => p.nbRawPointages),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: {
                        color: 'violet',
                        size: 8
                      },
                      line: {
                        color: 'violet',
                        width: 2,
                        dash: 'dashdot'
                      },
                      name: 'Pointages bruts'
                    }
                  ]}
                  layout={ {
                    width: '100%',
                    title: '',
                    xaxis: {
                      type: 'date',
                      autorange: true,
                      rangeslider: {range: [
                        moment.unix(Math.min(...pointages.map(p => p.timestamp))).format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD")
                      ]}
                    }
                  } }
                  config={{displayModeBar: true, locale: 'fr', locales: { 'fr': localeDictionary }}}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} textAlign="center" verticalAlign="middle">
                <Label color='yellow' size='big'>Statut réseau des badgeuses</Label>
              </Grid.Column>
              <Grid.Column width={12} textAlign="center" verticalAlign="middle">
                {
                  this.state.loading ?
                  <Loader active size='big' />
                  :
                  <Plot
                    data={
                      rpiHardware.map(rh => {
                        const randomColor = `rgb(${Math.round(Math.random()*255)},${Math.round(Math.random()*255)},${Math.round(Math.random()*255)})`
                        return {
                          x: rpiHardwareOnline.filter(rho => Object.keys(rho.isOnline).includes(rh)).map(rho => moment.unix(rho.timestamp).format("YYYY-MM-DD HH:mm:ss")),
                          y: rpiHardwareOnline.filter(rho => Object.keys(rho.isOnline).includes(rh)).map(rho => rho.isOnline[rh] === true ? 1 : 0),
                          text: rpiHardwareOnline.filter(rho => Object.keys(rho.isOnline).includes(rh)).map(rho => `${rh} : ` + (rho.isOnline[rh] === true ? 'En ligne' : 'Hors ligne')),
                          type: 'scatter',
                          mode: 'lines+markers',
                          marker: {
                            color: randomColor,
                            size: 8
                          },
                          line: {
                            color: randomColor,
                            width: 2
                          },
                          name: 'En ligne'
                        };
                      })
                    }
                    layout={ {
                      width: '100%',
                      title: '',
                      xaxis: {
                        type: 'date',
                        autorange: true,
                        rangeslider: {range: [
                          moment.unix(Math.min(...rpiHardwareOnline.map(rho => rho.timestamp))).format("YYYY-MM-DD"),
                          moment().format("YYYY-MM-DD")
                        ]}
                      }
                    } }
                    config={{displayModeBar: true, locale: 'fr', locales: { 'fr': localeDictionary }}}
                  />
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
      );
    } else {
      return;
    }
  }
}

export default CustomerDetail;
