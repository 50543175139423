import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Container,
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  List,
  Loader,
  Message,
  Modal,
  Popup,
  Table,
  TextArea
} from "semantic-ui-react";
import resolvePath from 'object-resolve-path';
import crypto from 'crypto';
import randomstring from 'randomstring';
import moment from 'moment';

const sha256 = (str) => crypto.createHash('sha256').update(str).digest('hex');
const randomToken = () => sha256(randomstring.generate(30));


class Storage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      orders: [],
      sortState_products: {
        column: 'name',
        direction: 'ascending'
      },
      sortState_orders: {
        column: 'orderDate',
        direction: 'descending'
      },
      editingProduct: undefined,
      editingOrder: undefined,
      loading: true,
      megaFilter: "",
      error: undefined,
      selectedProductIdToAdd: undefined,
      selectedProductUnitPriceToAdd: 0,
      selectedProductQuantityToAdd: 1
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    return this.getProducts().then(this.getOrders()).finally(() => {
      this.setState({loading: false});
    });
  }

  getProducts() {
    return this.props.rest().get('products')
    .then((products) => {
      this.setState({
        products: products.sort((x,y) => x.name < y.name ? -1 : 1),
      });
    }).catch((e) => {
      this.manageErrors(e);
    });
  }

  getOrders() {
    return this.props.rest().get('orders')
    .then((orders) => {
      this.setState({
        orders: orders.sort((x,y) => x.orderDate > y.orderDate ? -1 : 1),
      });
    }).catch((e) => {
      this.manageErrors(e);
    });
  }

  manageErrors(err) {
    if(err !== undefined) {
      this.setState({
        error: err.entity || err
      });
      setTimeout(() => {
        this.setState({
          error: undefined
        });
      }, 10000);
    }
  }

  handleSort(module) {
    return (clickedColumn) => {
      return () => {
        const mKey = `sortState_${module}`;
        const { column, direction } = this.state[mKey];
        const data = this.state[module];

        if (column !== clickedColumn) {
          this.setState({
            [mKey]: Object.assign(this.state[mKey], {
              column: clickedColumn,
              direction: 'ascending'
            }),
            [module]: data.sort((d1,d2) => resolvePath(d1, clickedColumn) < resolvePath(d2, clickedColumn) ? -1 : 1)
          });
          return;
        }

        this.setState({
          [mKey]: Object.assign(this.state[mKey], {
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
            column
          })
        });
      }
    };
  }

  onClickOnAddProduct(event) {
    const p = {
      name: "Nouveau produit",
      misc: ""
    };
    this.props.rest().post('products', p).then(result => {
      this.setState({
        editingProduct: undefined,
        products: [Object.assign(p, {id: result.id}), ...this.state.products.filter(x => x.id !== p.id)]
      });
    })
    .catch(e => this.manageErrors(e));
  }

  onChangeProductProp(propName) {
    return (event, data) => {
      this.setState({
        editingProduct: Object.assign({}, this.state.editingProduct, {
          [propName]: event.target.value
        })
      });
    }
  }

  onClickOnUpdProduct(p) {
    return (event) => {
      this.props.rest().put('products', p).then(p => {
        this.setState({
          editingProduct: undefined,
          products: [...this.state.products.filter(x => x.id !== p.id), p].sort((d1,d2) => resolvePath(d1, this.state.sortState_products.column) < resolvePath(d2, this.state.sortState_products.column) ? -1 : 1)
        });
      }).catch(e => this.manageErrors(e));
    };
  }

  onClickOnAddOrder(event) {
    const o = {
      from: "",
      misc: "",
      shippingPrice: 0.0,
      orderDate: moment().unix(),
      products: []
    };
    this.props.rest().post('orders', o).then(result => {
      this.setState({
        editingOrder: undefined,
        orders: [Object.assign(o, {id: result.id}), ...this.state.orders.filter(x => x.id !== o.id)]
      });
    })
    .catch(e => this.manageErrors(e));
  }

  onChangeOrderProp(propName) {
    return (event, data) => {
      this.setState({
        editingOrder: Object.assign({}, this.state.editingOrder, {
          [propName]: event.target.value
        })
      });
    }
  }

  onClickOnUpdOrder(o) {
    return (event) => {
      if(this.state.editingOrder.receptionDate !== null && this.state.editingOrder.receptionDate !== undefined) {
        if(!window.confirm("Attention, si vous renseignez la date de réception, la commande ne sera plus modifiable et les quantités seront ajoutées au stock.")) {
          return;
        }
      }
      this.props.rest().put('orders', o).then(o => {
        this.setState({
          editingOrder: undefined,
          orders: [...this.state.orders.filter(x => x.id !== o.id), o].sort((d1,d2) => resolvePath(d1, this.state.sortState_orders.column) < resolvePath(d2, this.state.sortState_orders.column) ? -1 : 1)
        });
      }).then(() => Promise.all(o.products.map(po => this.props.rest().put('products', {id: po.productId, quantityInStock: parseInt(this.state.products.find(x => x.id === po.productId).quantityInStock) + parseInt(po.quantity)}))))
      .then(() => this.getProducts())
      .catch(e => this.manageErrors(e));
    };
  }

  render() {
    const filteredProducts = this.state.products;
    /*.filter(x => this.state.megaFilter.length > 0 ?
      x.name.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      x.misc.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      x.simNumber.toLowerCase().includes(this.state.megaFilter.toLowerCase())
    : true)*/

    const filteredOrders = this.state.orders;
    /*.filter(x => this.state.megaFilter.length > 0 ?
      x.name.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      x.misc.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      x.simNumber.toLowerCase().includes(this.state.megaFilter.toLowerCase())
    : true)*/

    return (
        <Container className="adminContentClass" textAlign="center">
            <Grid padded>
              <Grid.Row>
                {
                  this.state.error !== undefined ?
                    <Message negative content={this.state.error.toString()} />
                    : ""
                }
              </Grid.Row>
              <Grid.Row style={{
                  backgroundColor: '#545454',
                  borderRadius: '10px'
                }}>
                <Grid.Column width={2}>
                  <Icon name='box' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={10} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Stock
                      <Header.Subheader>
                        Retrouvez ici la liste des produits ainsi que leur quantité en stock
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column>
                  <Button onClick={this.onClickOnAddProduct.bind(this)} data-tooltip="Ajouter un produit" data-position="bottom right" icon color='green'>
                    <Icon name='plus' />
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign: 'left'}} width={2}>
                  <Label size='large'>{filteredProducts.length} ligne(s) trouvée(s)</Label>
                </Grid.Column>
                <Grid.Column width={14}></Grid.Column>
              </Grid.Row>
              <Grid.Row style={{minHeight: '30vh'}}>
                {
                  this.state.loading === true ?
                  <Dimmer inverted active>
                    <Loader inverted />
                  </Dimmer>                
                  :
                  <Table striped selectable sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                        sorted={this.state.sortState_products.column === 'name' ? this.state.sortState_products.direction : null}
                        onClick={this.handleSort('products')('name').bind(this)}>
                          Nom
                        </Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState_products.column === 'misc' ? this.state.sortState_products.direction : null}
                        onClick={this.handleSort('products')('misc').bind(this)}>
                          Désignation
                        </Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState_products.column === 'quantityInStock' ? this.state.sortState_products.direction : null}
                        onClick={this.handleSort('products')('quantityInStock').bind(this)}>
                          Quantité en stock
                        </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        filteredProducts
                        .map((p, idx) => this.state.editingProduct !== undefined && this.state.editingProduct.id === p.id ? (
                          <Table.Row key={`details_storage_${idx}`}>
                            <Table.Cell width={3}>
                              <Input fluid onChange={this.onChangeProductProp('name').bind(this)} value={this.state.editingProduct.name} />
                            </Table.Cell>
                            <Table.Cell width={3}>
                              <Input fluid onChange={this.onChangeProductProp('misc').bind(this)} value={this.state.editingProduct.misc} />
                            </Table.Cell>
                            <Table.Cell width={1}>
                              <Input fluid onChange={this.onChangeProductProp('quantityInStock').bind(this)} value={this.state.editingProduct.quantityInStock} />
                            </Table.Cell>
                            <Table.Cell width={1}>
                              <Button.Group>
                                <Button color='green' data-tooltip="Enregistrer les modifications" data-position="bottom right" onClick={this.onClickOnUpdProduct(this.state.editingProduct).bind(this)}><Icon name='check' /></Button>
                              </Button.Group>
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          <Table.Row onClick={() => this.setState({editingProduct: p})} key={`details_storage_${idx}`}>
                            <Table.Cell width={3}>{p.name}</Table.Cell>
                            <Table.Cell width={3}>{p.misc}</Table.Cell>
                            <Table.Cell width={1}>{p.quantityInStock}</Table.Cell>
                            <Table.Cell width={1}>
                              <Button.Group>
                                <Button color='green'><Icon name='check' disabled /></Button>
                              </Button.Group>
                            </Table.Cell>
                          </Table.Row>
                        ))
                      }
                    </Table.Body>
                  </Table>
                }
              </Grid.Row>




              <Grid.Row style={{
                  backgroundColor: '#545454',
                  borderRadius: '10px'
                }}>
                <Grid.Column width={2}>
                  <Icon name='dollar sign' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={10} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Commandes consignées
                      <Header.Subheader>
                        Retrouvez ici la liste des commandes consignées
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column>
                  <Button onClick={this.onClickOnAddOrder.bind(this)} data-tooltip="Consigner une commande" data-position="bottom right" icon color='green'>
                    <Icon name='plus' />
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign: 'left'}} width={2}>
                  <Label size='large'>{filteredOrders.length} ligne(s) trouvée(s)</Label>
                </Grid.Column>
                <Grid.Column width={14}></Grid.Column>
              </Grid.Row>
              <Grid.Row style={{minHeight: '30vh'}}>
                {
                  this.state.loading === true ?
                  <Dimmer inverted active>
                    <Loader inverted />
                  </Dimmer>                
                  :
                  <Table striped selectable sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                        sorted={this.state.sortState_orders.column === 'orderDate' ? this.state.sortState_orders.direction : null}
                        onClick={this.handleSort('orders')('orderDate').bind(this)}>
                          Date
                        </Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState_orders.column === 'from' ? this.state.sortState_orders.direction : null}
                        onClick={this.handleSort('orders')('from').bind(this)}>
                          Fournisseur
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Produits
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Notes
                        </Table.HeaderCell>
                        <Table.HeaderCell
                        sorted={this.state.sortState_orders.column === 'shippingPrice' ? this.state.sortState_orders.direction : null}
                        onClick={this.handleSort('orders')('shippingPrice').bind(this)}>
                          Tarif livraison
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Total calculé
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Reçue
                        </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        filteredOrders
                        .map((o, idx) => this.state.editingOrder !== undefined && this.state.editingOrder.id === o.id ? (
                          <Table.Row key={`details_order_${idx}`}>
                            <Table.Cell width={2}>
                              <Input placeholder="DD/MM/YYYY" fluid onChange={(evt) => this.onChangeOrderProp('orderDate')({target:{value: moment(evt.target.value, "DD/MM/YYYY").unix()}})} defaultValue={moment.unix(this.state.editingOrder.orderDate).format("DD/MM/YYYY")} />
                            </Table.Cell>
                            <Table.Cell width={2}>
                              <Input fluid onChange={this.onChangeOrderProp('from').bind(this)} value={this.state.editingOrder.from} />
                            </Table.Cell>
                            <Table.Cell width={4}>
                              <Grid>
                                <Grid.Row>
                                  <List>
                                  {this.state.editingOrder.products.map((po, poIdx) => <List.Item key={`order_listitem_${poIdx}`}><Label.Group>
                                    <Label>
                                      {this.state.products.find(x => x.id === po.productId).name}
                                      <Label.Detail>{po.unitPrice} €</Label.Detail>
                                      <Icon name='delete' onClick={() => {
                                        this.setState({
                                          editingOrder: Object.assign({}, this.state.editingOrder, {
                                            products: this.state.editingOrder.products.filter(x => x.productId !== po.productId)
                                          })
                                        });
                                      }} />
                                    </Label>
                                    <Label circular>
                                      {po.quantity}
                                    </Label>
                                  </Label.Group></List.Item>
                                  )}
                                  </List>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column width={14}>
                                    <Dropdown
                                      placeholder='Choisissez le produit à ajouter'
                                      search
                                      selection
                                      fluid
                                      onChange={(e, data) => this.setState({selectedProductIdToAdd: data.value})}
                                      value={this.state.selectedProductIdToAdd}
                                      options={this.state.products.filter(p => this.state.editingOrder.products.map(x => x.productId).includes(p.id) === false).map(p => ({
                                        key: `product_${p.id}`,
                                        text: p.name,
                                        value: p.id
                                      }))}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column width={5}>
                                    <Input onChange={(e) => this.setState({selectedProductQuantityToAdd: e.target.value})} value={this.state.selectedProductQuantityToAdd} fluid label="Qté." />
                                  </Grid.Column>
                                  <Grid.Column width={5}>
                                    <Input onChange={(e) => this.setState({selectedProductUnitPriceToAdd: e.target.value})} value={this.state.selectedProductUnitPriceToAdd} fluid label="P.U." />
                                  </Grid.Column>
                                  <Grid.Column width={1}>
                                    <Button onClick={() => { 
                                      if(this.state.selectedProductIdToAdd === undefined || parseInt(this.state.selectedProductQuantityToAdd) <= 0) return;
                                      this.setState({
                                        selectedProductIdToAdd: undefined,
                                        editingOrder: Object.assign({}, this.state.editingOrder, {
                                          products: [...this.state.editingOrder.products, {productId: this.state.selectedProductIdToAdd, quantity: this.state.selectedProductQuantityToAdd, unitPrice: this.state.selectedProductUnitPriceToAdd}]
                                        })
                                      });
                                    }} color='green' data-tooltip="Ajouter le produit" data-position="top right"><Icon name='plus' /></Button>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Table.Cell>
                            <Table.Cell width={2}>
                              <Input fluid onChange={this.onChangeOrderProp('misc').bind(this)} value={this.state.editingOrder.misc} />
                            </Table.Cell>
                            <Table.Cell width={1}>
                              <Input fluid onChange={this.onChangeOrderProp('shippingPrice').bind(this)} value={this.state.editingOrder.shippingPrice} />
                            </Table.Cell>
                            <Table.Cell width={1}>
                              {(o.products.reduce((acc, po) => acc+parseFloat(po.quantity)*parseFloat(po.unitPrice), 0.0)+parseFloat(o.shippingPrice)).toFixed(2)} €
                            </Table.Cell>
                            <Table.Cell width={2}>
                              <Input fluid placeholder="DD/MM/YYYY" onChange={(evt) => this.onChangeOrderProp('receptionDate')({target:{value: evt.target.value === "" ? null : moment(evt.target.value, "DD/MM/YYYY").unix()}})} defaultValue={this.state.editingOrder.receptionDate ? moment.unix(this.state.editingOrder.receptionDate).format("DD/MM/YYYY") : ""}  />
                            </Table.Cell>
                            <Table.Cell width={1}>
                              <Button.Group>
                                <Button color={o.receptionDate === null || o.receptionDate === undefined ? 'green' : 'blue'} data-tooltip="Enregistrer les modifications" data-position="bottom right" onClick={this.onClickOnUpdOrder(this.state.editingOrder).bind(this)}><Icon name={o.receptionDate === null || o.receptionDate === undefined ? 'check' : 'lock'} /></Button>
                              </Button.Group>
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          <Table.Row onClick={() => o.receptionDate === null || o.receptionDate === undefined ? this.setState({editingOrder: o}) : true} key={`details_order_${idx}`}>
                            <Table.Cell width={2}>{moment.unix(o.orderDate).format("DD/MM/YYYY")}</Table.Cell>
                            <Table.Cell width={2}>{o.from}</Table.Cell>
                            <Table.Cell width={4}>
                              <List>
                              {o.products.map((po, idx) => <List.Item key={`listItem_product_${idx}`}><Label.Group>
                                <Label>
                                  {this.state.products.find(x => x.id === po.productId).name}
                                  <Label.Detail>{po.unitPrice} €</Label.Detail>
                                </Label>
                                <Label circular>
                                  {po.quantity}
                                </Label>
                              </Label.Group></List.Item>
                              )}
                              </List>
                            </Table.Cell>
                            <Table.Cell width={2}>{o.misc}</Table.Cell>
                            <Table.Cell width={1}>{o.shippingPrice} €</Table.Cell>
                            <Table.Cell width={1}>
                              {o.products.reduce((acc, po) => acc+po.quantity*po.unitPrice, 0.0)+o.shippingPrice} €
                            </Table.Cell>
                            <Table.Cell width={2}>{o.receptionDate ? "Le " + moment.unix(o.receptionDate).format("DD/MM/YYYY") : "Non"}</Table.Cell>
                            <Table.Cell width={1}>
                              {
                                <Button.Group>
                                  <Button color={o.receptionDate === null || o.receptionDate === undefined ? 'green' : 'blue'}><Icon name={o.receptionDate === null || o.receptionDate === undefined ? 'check' : 'lock'} disabled /></Button>
                                </Button.Group>
                              }
                            </Table.Cell>
                          </Table.Row>
                        ))
                      }
                    </Table.Body>
                  </Table>
                }
              </Grid.Row>
            </Grid>
        </Container>
    );
  }
}

export default Storage;
