import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Checkbox,
  Container,
  Dimmer,
  Divider,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Table
} from "semantic-ui-react";
import CustomerDetail from './CustomerDetail';
import CONFIG from './utils/config';
import { saveAs } from 'file-saver';
import iconv from 'iconv-lite';
import moment from 'moment';
import Plot from 'react-plotly.js';
import * as localeDictionary from 'plotly.js/lib/locales/fr.js'

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: {
        customers: []
      },
      selectedCustomer: undefined,
      displayActiveAccounts: true,
      displayDemoAccounts: false,
      displayLockedAccounts: false,
      displayTerminatedAccounts: false,
      monthPriceAsPercent: false,
      graphAsVariations: false,
      displayNotes: false,
      displayOnlyBillable: false,
      shouldBeBilled: 1,
      displayOnlyPositiveDelta: false,
      loading: false,
      exportLoading: false,
      megaFilter: "",
      sortState: {}
    };
  }

  componentDidMount() {
    this.setState({loading: true}, () => {
      this.props.rest().get('stats').then((stats) => {
        this.setState({
          stats: Object.assign({}, stats, {
            customers: stats.customers
          })
        });
      }).catch(e => console.log(JSON.stringify(e))).finally(() => {
        this.setState({loading: false});
      });
    });
  }

  openCustomerDetail(customer) {
    return (event) => {
      this.setState({
        selectedCustomer: customer
      });
    };
  }

  getFilenameForExport(extension) {
    const now = moment();
    return `ORANE_ADMIN_EXPORT_${now.format("YYYYMMDD_HH[H]mm[M]ss[S]")}.${extension}`;
  }

  onExportCSV(evt) {
    // TODO : % par mois ?
    this.setState({exportLoading: true}, () => {
      const dataToExport = ['ID;Nom;Code;Date de souscription;Nb. employés;(actifs);(delta);Nb. profils;Nb. murales;(louées);Nb. nomades;(louées);Nb. Applis.;Nb. GPS;Nb. pointages;(bruts);Type;Utilisé;€ / mois;Notes', ...this.getCustomersWithTotal()
        .filter(c => this.state.displayActiveAccounts === false ? c.type !== 'ACTIVE' : true)
        .filter(c => this.state.displayDemoAccounts === false ? c.type !== 'DEMO' : true)
        .filter(c => this.state.displayLockedAccounts === false ? c.type !== 'LOCKED' : true)
        .filter(c => this.state.displayTerminatedAccounts === false ? c.type !== 'TERMINATED' : true)
        .filter(c => this.state.displayOnlyBillable === true ? c.shouldBeBilled === this.state.shouldBeBilled : true)
        .filter(c => this.state.displayOnlyPositiveDelta === true ? (c.nbEmployeesActiveVariation.current.nb - c.nbEmployeesActiveVariation.previous.nb) > 0 : true)
        .map(c => `${c.id};${c.name};${c.code};${moment.unix(c.subscriptionStart).format("DD/MM/YYYY")};${c.nbEmployees};${c.nbEmployeesActive};${c.nbEmployeesActiveVariation.current.nb - c.nbEmployeesActiveVariation.previous.nb};${c.nbProfiles};${c.nbRpiHardware};${c.nbRpiHardwareRent};${c.nbPhoneHardware};${c.nbPhoneHardwareRent};${c.phoneSoftware};${c.nbTraccarHardware};${c.nbPointages};${c.nbRawPointages};${c.type};${c.isUsingHisAccount};${c.totalPerMonth.toFixed(2)};${c.notes}`)
      ];
      const contentCSV = dataToExport.reduce((acc, line) => `${acc}${line}\r\n`, '');
      const decodedBuffer = iconv.decode(Buffer.from(contentCSV), 'utf8');
      const contentBuffer = iconv.encode(decodedBuffer, 'win1252');
      this.setState({exportLoading: false}, () => {
        saveAs(new Blob([contentBuffer], {type: "text/csv;charset=windows-1252"}), this.getFilenameForExport('csv'));
      });
    });
  }

  onChangeShouldBeBilled(value) {
    return (e) => {
      if(value > 3 || value < 1) {
        value = 1;
      }
      this.setState({
        shouldBeBilled: value
      });
    };
  }
  
  handleSort(clickedColumn) {
    return () => {
      const { column, direction } = this.state.sortState;
      this.setState({
        sortState: Object.assign(this.state.sortState, {
          direction: direction === 'ascending' ? 'descending' : 'ascending',
          column: clickedColumn
        })
      });
    }
  }

  getCustomersWithTotal() {
    return this.state.stats.customers.map(c => Object.assign({}, c, {
      totalPerMonth: (
        c.nbEmployeesActive * CONFIG.prices.ORANE_MONTHLY_PRICE_PER_ACTIVE_EMPLOYEE
        +
        c.nbRpiHardwareRent * CONFIG.prices.RPIHARDWARE_MONTHLY_PRICE
        +
        c.nbPhoneHardwareRent * CONFIG.prices.PHONEHARDWARE_MONTHLY_PRICE
        +
        c.phoneSoftware * CONFIG.prices.PHONESOFTWARE_MONTHLY_PRICE
        +
        c.nbTraccarHardware * CONFIG.prices.TRACCARHARDWARE_MONTHLY_PRICE
      )
    }));
  }

  render() {
    const onlyActive = (c) => c.type === 'ACTIVE';
    const onlyNotUsingAccount = (c) => c.isUsingHisAccount === false;
    const oraneTotal = Number.parseFloat(this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbEmployeesActive, 0) * CONFIG.prices.ORANE_MONTHLY_PRICE_PER_ACTIVE_EMPLOYEE);
    const rpiTotal = Number.parseFloat(this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbRpiHardwareRent, 0) * CONFIG.prices.RPIHARDWARE_MONTHLY_PRICE);
    const phoneTotal = Number.parseFloat(this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbPhoneHardwareRent, 0) * CONFIG.prices.PHONEHARDWARE_MONTHLY_PRICE);
    const appsTotal = Number.parseFloat(this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.phoneSoftware, 0) * CONFIG.prices.PHONESOFTWARE_MONTHLY_PRICE);
    const gpsTotal = Number.parseFloat(this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbTraccarHardware, 0) * CONFIG.prices.TRACCARHARDWARE_MONTHLY_PRICE);
    
    const oraneTotalNotUsingTheirAccount =
      Number.parseFloat(this.state.stats.customers.filter(onlyActive).filter(onlyNotUsingAccount).reduce((acc, c) => acc+c.nbEmployeesActive, 0) * CONFIG.prices.ORANE_MONTHLY_PRICE_PER_ACTIVE_EMPLOYEE)
      + Number.parseFloat(this.state.stats.customers.filter(onlyActive).filter(onlyNotUsingAccount).reduce((acc, c) => acc+c.nbRpiHardwareRent, 0) * CONFIG.prices.RPIHARDWARE_MONTHLY_PRICE)
      + Number.parseFloat(this.state.stats.customers.filter(onlyActive).filter(onlyNotUsingAccount).reduce((acc, c) => acc+c.nbPhoneHardwareRent, 0) * CONFIG.prices.PHONEHARDWARE_MONTHLY_PRICE)
      + Number.parseFloat(this.state.stats.customers.filter(onlyActive).filter(onlyNotUsingAccount).reduce((acc, c) => acc+c.nbTraccarHardware, 0) * CONFIG.prices.TRACCARHARDWARE_MONTHLY_PRICE)
    ;

    const globalTotal = oraneTotal + rpiTotal + phoneTotal + appsTotal + gpsTotal;

    const histories = this.state.stats.customers.map(c => c.history);
    const timestamps = histories.map(h => {
      const {rpiH, phoneH, gps, apps} = h;
      return [
        ...rpiH.map(x => x.timestamp),
        ...phoneH.map(x => x.timestamp),
        ...gps.map(x => x.timestamp),
        ...apps.map(x => x.timestamp)
      ];
    }).flat().filter((x, idx, self) => self.indexOf(x) === idx).sort();
    const mapAsVariation = (({x,y}, idx, tbl) => {
      if(this.state.graphAsVariations !== true) {
        return ({x,y});
      }
      if(idx === 0) {
        return null;
      }
      const previousPoint = tbl[idx-1];
      return ({x, y: (y-previousPoint.y)/previousPoint.y});
    });
    const rawMoneyPlotData = timestamps.map(t => {
      const moneyThatDay = histories.reduce((acc, h) => {
        const {rpiH, phoneH, gps, apps, employees} = h;
        const todayRpiH = (rpiH.find(x => x.timestamp === t) || {nbRpiH: {rent: 0}}).nbRpiH.rent;
        const todayPhoneH = (phoneH.find(x => x.timestamp === t) || {nbPhoneH: {rent: 0}}).nbPhoneH.rent;
        const todayGps = (gps.find(x => x.timestamp === t) || {nbGps: 0}).nbGps;
        const todayApps = (apps.find(x => x.timestamp === t) || {nbApps: 0}).nbApps;
        const todayEmployeesActive = (employees.find(x => x.timestamp === t) || ({nbEmployeesActive: 0})).nbEmployeesActive;
        const todayTotal = todayRpiH * CONFIG.prices.RPIHARDWARE_MONTHLY_PRICE
          + todayPhoneH * CONFIG.prices.PHONEHARDWARE_MONTHLY_PRICE
          + todayGps * CONFIG.prices.TRACCARHARDWARE_MONTHLY_PRICE
          + todayApps * CONFIG.prices.PHONESOFTWARE_MONTHLY_PRICE
          + todayEmployeesActive * CONFIG.prices.ORANE_MONTHLY_PRICE_PER_ACTIVE_EMPLOYEE;
        return acc + todayTotal;
      }, 0.0);
      return ({
        x: t,
        y: moneyThatDay
      });
    });

    const mapAsPercent = ({x, y}) => {
      const total = rawMoneyPlotData.find(total => total.x === x);
      if(this.state.monthPriceAsPercent === false || (this.state.monthPriceAsPercent === true && this.state.graphAsVariations === true) || total === undefined) {
        return ({x,y});
      }
      return ({x, y: y/total.y});
    };

    const moneyPlotData = rawMoneyPlotData
    .map(({x, y}) => this.state.monthPriceAsPercent && this.state.graphAsVariations === false ? ({x, y: 1.0}) : ({x,y}))
    .map(mapAsVariation).filter(x => x !== null);

    const moneyPlotOraneData = timestamps.map(t => {
      const moneyThatDay = histories.reduce((acc, h) => {
        const {employees} = h;
        const todayEmployeesActive = (employees.find(x => x.timestamp === t) || ({nbEmployeesActive: 0})).nbEmployeesActive;
        const todayTotal = todayEmployeesActive * CONFIG.prices.ORANE_MONTHLY_PRICE_PER_ACTIVE_EMPLOYEE;
        return acc + todayTotal;
      }, 0.0);
      return ({
        x: t,
        y: moneyThatDay
      });
    })
    .map(mapAsPercent)
    .map(mapAsVariation).filter(x => x !== null);
    const moneyPlotRpiHData = timestamps.map(t => {
      const moneyThatDay = histories.reduce((acc, h) => {
        const {rpiH} = h;
        const todayRpiH = (rpiH.find(x => x.timestamp === t) || {nbRpiH: {rent: 0}}).nbRpiH.rent;
        const todayTotal = todayRpiH * CONFIG.prices.RPIHARDWARE_MONTHLY_PRICE;
        return acc + todayTotal;
      }, 0.0);
      return ({
        x: t,
        y: moneyThatDay
      });
    })
    .map(mapAsPercent)
    .map(mapAsVariation).filter(x => x !== null);
    const moneyPlotPhoneHData = timestamps.map(t => {
      const moneyThatDay = histories.reduce((acc, h) => {
        const {phoneH} = h;
        const todayPhoneH = (phoneH.find(x => x.timestamp === t) || {nbPhoneH: {rent: 0}}).nbPhoneH.rent;
        const todayTotal = todayPhoneH * CONFIG.prices.PHONEHARDWARE_MONTHLY_PRICE;
        return acc + todayTotal;
      }, 0.0);
      return ({
        x: t,
        y: moneyThatDay
      });
    })
    .map(mapAsPercent)
    .map(mapAsVariation).filter(x => x !== null);
    const moneyPlotGpsData = timestamps.map(t => {
      const moneyThatDay = histories.reduce((acc, h) => {
        const {gps} = h;
        const todayGps = (gps.find(x => x.timestamp === t) || {nbGps: 0}).nbGps;
        const todayTotal = todayGps * CONFIG.prices.TRACCARHARDWARE_MONTHLY_PRICE;
        return acc + todayTotal;
      }, 0.0);
      return ({
        x: t,
        y: moneyThatDay
      });
    })
    .map(mapAsPercent)
    .map(mapAsVariation).filter(x => x !== null);
    const moneyPlotAppsData = timestamps.map(t => {
      const moneyThatDay = histories.reduce((acc, h) => {
        const {apps} = h;
        const todayApps = (apps.find(x => x.timestamp === t) || {nbApps: 0}).nbApps;
        const todayTotal = todayApps * CONFIG.prices.PHONESOFTWARE_MONTHLY_PRICE;
        return acc + todayTotal;
      }, 0.0);
      return ({
        x: t,
        y: moneyThatDay
      });
    })
    .map(mapAsPercent)
    .map(mapAsVariation).filter(x => x !== null);

    const customersWithTotal = this.getCustomersWithTotal();

    const prefilteredCustomers = customersWithTotal
    .filter(c => this.state.displayActiveAccounts === false ? c.type !== 'ACTIVE' : true)
    .filter(c => this.state.displayDemoAccounts === false ? c.type !== 'DEMO' : true)
    .filter(c => this.state.displayLockedAccounts === false ? c.type !== 'LOCKED' : true)
    .filter(c => this.state.displayTerminatedAccounts === false ? c.type !== 'TERMINATED' : true)
    .filter(c => this.state.displayOnlyBillable === true ? c.shouldBeBilled === this.state.shouldBeBilled : true)
    .filter(c => this.state.displayOnlyPositiveDelta === true ? (() => {
      const {previous, current} = c.nbEmployeesActiveVariation;
      return current.nb - previous.nb > 0;
    })() : true)
    .filter(c => this.state.megaFilter.length > 0 ?
      c.id.toString().toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.name.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.email.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.code.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.notes.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.rpiHardware).map(k => k.toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.phoneHardware).map(k => k.toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.phoneHardware).map(k => (c.phoneHardware[k].imei || "").toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.phoneHardware).map(k => (c.phoneHardware[k].simNumber || "").toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase())
    : true)
    .sort((c1, c2) => {
      switch(this.state.sortState.column) {
        case "name":
        case "code":
        case "nbEmployees":
        case "nbProfiles":
        case "nbRpiHardware":
        case "nbPhoneHardware":
        case "phoneSoftware":
        case "nbTraccarHardware":
        case "nbPointages":
        case "type":
        case "isUsingHisAccount":
        case "totalPerMonth":
          if(c1[this.state.sortState.column] === undefined) {
            return 1;
          } else if(c2[this.state.sortState.column] === undefined) {
            return -1;
          } else {
            return c1[this.state.sortState.column] < c2[this.state.sortState.column] ? -1 : 1;
          }
        default:
          return 0;
      }
    });

    const filteredCustomers = (this.state.sortState.direction === 'descending' ? prefilteredCustomers.reverse() : prefilteredCustomers);
    const linesTotal = this.state.monthPriceAsPercent ? 
      (filteredCustomers.reduce((acc, c) => acc+(c.totalPerMonth/globalTotal), 0.0) * 100.0).toFixed(2) :
      filteredCustomers.reduce((acc, c) => acc+c.totalPerMonth, 0.0).toFixed(2);
    const moneySuffix = this.state.monthPriceAsPercent ? '%' : '€';

    return (
        <Container className="adminContentClass" textAlign="center">
          {
            <Grid padded>
              <Grid.Row style={{
                backgroundColor: '#545454',
                borderRadius: '10px'
              }}>
                <Grid.Column width={2}>
                  <Icon name='euro sign' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={10} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      CA mensuel instantané
                      <Header.Subheader>
                        Une estimation de ce que rapporteront les contrats sur le mois en cours (bases actives uniquement).
                      </Header.Subheader>
                      <br />
                      <Header.Subheader>
                        <Grid columns={16}>
                          <Grid.Row>
                            <Grid.Column width={3}></Grid.Column>
                            <Grid.Column width={5}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.monthPriceAsPercent} onChange={() => {
                                this.setState({monthPriceAsPercent: !this.state.monthPriceAsPercent});
                              }} />
                              <br /><br />
                              <Label>Afficher les mensualités en % du CA</Label>
                            </Grid.Column>
                            <Grid.Column width={5}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.graphAsVariations} onChange={() => {
                                this.setState({graphAsVariations: !this.state.graphAsVariations});
                              }} />
                              <br /><br />
                              <Label>Afficher plutôt le graphique des variations de CA</Label>
                            </Grid.Column>
                            <Grid.Column width={3}></Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}></Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Label.Group>
                    <Label size='big' color='teal'>
                      Employés
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbEmployees, 0)}</Label.Detail>
                    </Label>
                    <Label size='big' color='yellow'>
                      Pointeuses
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbRpiHardware, 0)} ({this.state.loading === true ? <Loader inverted inline active size='tiny' /> : this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbRpiHardwareRent, 0)})</Label.Detail>
                    </Label>
                    <Label size='big' color='green'>
                      Téléphones
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbPhoneHardware, 0)} ({this.state.loading === true ? <Loader inverted inline active size='tiny' /> : this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbPhoneHardwareRent, 0)})</Label.Detail>
                    </Label>
                    <Label size='big' color='pink'>
                      Applications
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.phoneSoftware, 0)}</Label.Detail>
                    </Label>
                    <Label size='big' color='blue'>
                      Boitiers GPS
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbTraccarHardware, 0)}</Label.Detail>
                    </Label>
                    <Label size='big' color='black'>
                      Clients
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : this.state.stats.customers.filter(onlyActive).length || 0}</Label.Detail>
                    </Label>
                    <Label size='big' color='olive'>
                      Profils
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbProfiles, 0)}</Label.Detail>
                    </Label>
                    <Label size='big' color='purple'>
                      Pointages
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : this.state.stats.customers.filter(onlyActive).reduce((acc, c) => acc+c.nbPointages, 0)}</Label.Detail>
                    </Label>
                  </Label.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Label.Group>
                    <Label size='big' color='teal'>
                      ORANE
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : (this.state.monthPriceAsPercent ? (oraneTotal / globalTotal) * 100.0 : oraneTotal).toFixed(2) + ` ${moneySuffix}`}</Label.Detail>
                    </Label>
                    <Label size='big' color='yellow'>
                      Pointeuses
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : (this.state.monthPriceAsPercent ? (rpiTotal / globalTotal) * 100.0 : rpiTotal).toFixed(2) + ` ${moneySuffix}`}</Label.Detail>
                    </Label>
                    <Label size='big' color='green'>
                      Téléphones
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : (this.state.monthPriceAsPercent ? (phoneTotal / globalTotal) * 100.0 : phoneTotal).toFixed(2) + ` ${moneySuffix}`}</Label.Detail>
                    </Label>
                    <Label size='big' color='pink'>
                      Applications
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : (this.state.monthPriceAsPercent ? (appsTotal / globalTotal) * 100.0 : appsTotal).toFixed(2) + ` ${moneySuffix}`}</Label.Detail>
                    </Label>
                    <Label size='big' color='blue'>
                      Boitiers GPS
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : (this.state.monthPriceAsPercent ? (gpsTotal / globalTotal) * 100.0 : gpsTotal).toFixed(2) + ` ${moneySuffix}`}</Label.Detail>
                    </Label>
                    <Label size='big' color='black'>
                      Total
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : (this.state.monthPriceAsPercent ? 100.0 : globalTotal).toFixed(2) + ` ${moneySuffix}`}</Label.Detail>
                    </Label>
                    <Label size='big' color='grey'>
                      Total comptes inutilisés
                      <Label.Detail>{this.state.loading === true ? <Loader inverted inline active size='tiny' /> : (this.state.monthPriceAsPercent ? (oraneTotalNotUsingTheirAccount / globalTotal) * 100.0 : oraneTotalNotUsingTheirAccount).toFixed(2) + ` ${moneySuffix}`}</Label.Detail>
                    </Label>
                  </Label.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Plot
                    data={[
                      {
                        x: moneyPlotOraneData.map(({x,y}) => moment.unix(x).format("YYYY-MM-DD HH:mm:ss")),
                        y: moneyPlotOraneData.map(({x,y}) => y),
                        type: 'scatter',
                        mode: 'lines+markers',
                        marker: {
                          color: 'teal',
                          size: 8
                        },
                        line: {
                          color: 'teal',
                          width: 2,
                          smoothing: 1.3,
                          shape: 'spline'
                        },
                        name: 'ORANE',
                        visible: 'legendonly'
                      },
                      {
                        x: moneyPlotRpiHData.map(({x,y}) => moment.unix(x).format("YYYY-MM-DD HH:mm:ss")),
                        y: moneyPlotRpiHData.map(({x,y}) => y),
                        type: 'scatter',
                        mode: 'lines+markers',
                        marker: {
                          color: 'orange',
                          size: 8
                        },
                        line: {
                          color: 'orange',
                          width: 2,
                          smoothing: 1.3,
                          shape: 'spline'
                        },
                        name: 'Pointeuses',
                        visible: 'legendonly'
                      },
                      {
                        x: moneyPlotPhoneHData.map(({x,y}) => moment.unix(x).format("YYYY-MM-DD HH:mm:ss")),
                        y: moneyPlotPhoneHData.map(({x,y}) => y),
                        type: 'scatter',
                        mode: 'lines+markers',
                        marker: {
                          color: 'green',
                          size: 8
                        },
                        line: {
                          color: 'green',
                          width: 2,
                          smoothing: 1.3,
                          shape: 'spline'
                        },
                        name: 'Téléphones',
                        visible: 'legendonly'
                      },
                      {
                        x: moneyPlotAppsData.map(({x,y}) => moment.unix(x).format("YYYY-MM-DD HH:mm:ss")),
                        y: moneyPlotAppsData.map(({x,y}) => y),
                        type: 'scatter',
                        mode: 'lines+markers',
                        marker: {
                          color: 'pink',
                          size: 8
                        },
                        line: {
                          color: 'pink',
                          width: 2,
                          smoothing: 1.3,
                          shape: 'spline'
                        },
                        name: 'Applications',
                        visible: 'legendonly'
                      },
                      {
                        x: moneyPlotGpsData.map(({x,y}) => moment.unix(x).format("YYYY-MM-DD HH:mm:ss")),
                        y: moneyPlotGpsData.map(({x,y}) => y),
                        type: 'scatter',
                        mode: 'lines+markers',
                        marker: {
                          color: 'blue',
                          size: 8
                        },
                        line: {
                          color: 'blue',
                          width: 2,
                          smoothing: 1.3,
                          shape: 'spline'
                        },
                        name: 'Boitiers GPS',
                        visible: 'legendonly'
                      },
                      {
                        x: moneyPlotData.map(({x,y}) => moment.unix(x).format("YYYY-MM-DD HH:mm:ss")),
                        y: moneyPlotData.map(({x,y}) => y),
                        type: 'scatter',
                        mode: 'lines+markers',
                        marker: {
                          color: 'black',
                          size: 8
                        },
                        line: {
                          color: 'black',
                          width: 2,
                          smoothing: 1.3,
                          shape: 'spline'
                        },
                        name: 'Total'
                      }
                    ]}
                    layout={ {
                      autosize: true,
                      title: `Évolution ${this.state.graphAsVariations ? 'des variations ' : ''}du CA mensuel instantané`,
                      showlegend: true,
                      uirevision: 'true',
                      xaxis: {
                        title: '',
                        type: 'date',
                        autorange: true,
                        /*rangeslider: {range: [
                          moment.unix(Math.min(...timestamps)).format("YYYY-MM-DD"),
                          moment().format("YYYY-MM-DD")
                        ]},*/
                      },
                      yaxis: {
                        title: '',
                        tickformat: this.state.monthPriceAsPercent ? ".2%" : (this.state.graphAsVariations ? "+.2%" : ".2f")
                      }
                    } }
                    useResizeHandler={true}
                    style={{width: '100%', height: '100%'}}
                    config={{displayModeBar: true, locale: 'fr', locales: { 'fr': localeDictionary }}}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{
                backgroundColor: '#545454',
                borderRadius: '10px'
              }}>
                <Grid.Column width={2}>
                  <Icon size='huge' name='user' bordered color='blue' inverted circular />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={10} textAlign="center" verticalAlign="middle">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Par client
                      <Header.Subheader>
                        Cliquez sur le bouton en fin de ligne pour plus de détails.
                      </Header.Subheader>
                      <br />
                      <Header.Subheader>
                        <Grid columns={16}>
                          <Grid.Row>
                            <Grid.Column width={1}></Grid.Column>
                            <Grid.Column width={3}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayActiveAccounts} onChange={() => {
                                this.setState({displayActiveAccounts: !this.state.displayActiveAccounts});
                              }} />
                              <br /><br />
                              <Label>Afficher les bases actives</Label>
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayDemoAccounts} onChange={() => {
                                this.setState({displayDemoAccounts: !this.state.displayDemoAccounts});
                              }} />
                              <br /><br />
                              <Label>Afficher les bases de démo</Label>
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayLockedAccounts} onChange={() => {
                                this.setState({displayLockedAccounts: !this.state.displayLockedAccounts});
                              }} />
                              <br /><br />
                              <Label>Afficher les bases verrouillées</Label>
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayTerminatedAccounts} onChange={() => {
                                this.setState({displayTerminatedAccounts: !this.state.displayTerminatedAccounts});
                              }} />
                              <br /><br />
                              <Label>Afficher les bases résiliées</Label>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayNotes} onChange={() => {
                                this.setState({displayNotes: !this.state.displayNotes});
                              }} />
                              <br /><br />
                              <Label>Afficher les notes</Label>
                            </Grid.Column>
                            <Grid.Column width={1}></Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={3}></Grid.Column>
                            <Grid.Column width={5}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayOnlyBillable} onChange={() => {
                                this.setState({displayOnlyBillable: !this.state.displayOnlyBillable});
                              }} />
                              <br /><br />
                              <Label>Afficher seulement les comptes "à facturer"</Label>
                              <br /><br />
                              {
                                this.state.displayOnlyBillable === true ?
                                  [<Label as='a' key='LBL_BILLABLE_CURRENT' color={this.state.displayOnlyBillable && this.state.shouldBeBilled === 1 ? 'blue' : 'grey'} onClick={this.onChangeShouldBeBilled(1).bind(this)}>Ce mois ci</Label>,
                                  <Label as='a' key='LBL_BILLABLE_NEXT' color={this.state.displayOnlyBillable && this.state.shouldBeBilled === 2 ? 'blue' : 'grey'} onClick={this.onChangeShouldBeBilled(2).bind(this)}>Le mois prochain</Label>,
                                  <Label as='a' key='LBL_BILLABLE_NEXT_NEXT' color={this.state.displayOnlyBillable && this.state.shouldBeBilled === 3 ? 'blue' : 'grey'} onClick={this.onChangeShouldBeBilled(3).bind(this)}>Dans deux mois</Label>]
                                : ''
                              }
                            </Grid.Column>
                            <Grid.Column width={5}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayOnlyPositiveDelta} onChange={() => {
                                this.setState({displayOnlyPositiveDelta: !this.state.displayOnlyPositiveDelta});
                              }} />
                              <br /><br />
                              <Label>Afficher seulement les comptes avec un delta &gt; 0</Label>
                            </Grid.Column>
                            <Grid.Column width={3}></Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column><span data-position="bottom right" data-tooltip="Exporter le tableau en CSV"><Button onClick={this.onExportCSV.bind(this)} loading={this.state.exportLoading} color='green' icon='file excel' /></span></Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Input
                    placeholder='Recherche libre'
                    icon='search'
                    fluid
                    onChange={(evt) => this.setState({megaFilter: evt.target.value})}
                    value={this.state.megaFilter}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign: 'left'}} width={2}>
                  <Label size='large'>{filteredCustomers.length} ligne(s) trouvée(s)</Label>
                </Grid.Column>
                <Grid.Column width={11}></Grid.Column>
                <Grid.Column style={{textAlign: 'right'}} width={3}>
                  <Label color='black' size='large'>Total des lignes : {linesTotal} {moneySuffix}</Label>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{minHeight: '30vh'}}>
              {
                  this.state.loading === true ?
                  <Dimmer inverted active>
                    <Loader inverted />
                  </Dimmer>
                  :
                    this.state.selectedCustomer !== undefined ?
                    <CustomerDetail rest={this.props.rest} close={this.openCustomerDetail(undefined).bind(this)} customer={this.state.selectedCustomer} />
                    :
                    <Table textAlign='center' selectable sortable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            onClick={this.handleSort('name').bind(this)}
                            sorted={this.state.sortState.column === 'name' ? this.state.sortState.direction : null}>
                            Nom
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            onClick={this.handleSort('code').bind(this)}
                            sorted={this.state.sortState.column === 'code' ? this.state.sortState.direction : null}>
                            Code
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            onClick={this.handleSort('subscriptionStart').bind(this)}
                            sorted={this.state.sortState.column === 'subscriptionStart' ? this.state.sortState.direction : null}>
                            Date de souscription
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width={1}
                            onClick={this.handleSort('nbEmployees').bind(this)}
                            sorted={this.state.sortState.column === 'nbEmployees' ? this.state.sortState.direction : null}>
                            Employés (actifs) (delta)
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width={1}
                            onClick={this.handleSort('nbRpiHardware').bind(this)}
                            sorted={this.state.sortState.column === 'nbRpiHardware' ? this.state.sortState.direction : null}>
                            Pointeuses (louées)
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width={1}
                            onClick={this.handleSort('nbPhoneHardware').bind(this)}
                            sorted={this.state.sortState.column === 'nbPhoneHardware' ? this.state.sortState.direction : null}>
                            Téléphones (loués)
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width={1}
                            onClick={this.handleSort('phoneSoftware').bind(this)}
                            sorted={this.state.sortState.column === 'phoneSoftware' ? this.state.sortState.direction : null}>
                            Applis.
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width={1}
                            onClick={this.handleSort('nbTraccarHardware').bind(this)}
                            sorted={this.state.sortState.column === 'nbTraccarHardware' ? this.state.sortState.direction : null}>
                            Boitiers GPS
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width={2}
                            onClick={this.handleSort('nbPointages').bind(this)}
                            sorted={this.state.sortState.column === 'nbPointages' ? this.state.sortState.direction : null}>
                            Pointages (bruts)
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            onClick={this.handleSort('type').bind(this)}
                            sorted={this.state.sortState.column === 'type' ? this.state.sortState.direction : null}>
                            Type
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            onClick={this.handleSort('isUsingHisAccount').bind(this)}
                            sorted={this.state.sortState.column === 'isUsingHisAccount' ? this.state.sortState.direction : null}>
                            Utilisé
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            onClick={this.handleSort('totalPerMonth').bind(this)}
                            sorted={this.state.sortState.column === 'totalPerMonth' ? this.state.sortState.direction : null}>
                            {moneySuffix} / mois
                          </Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {
                          filteredCustomers
                          .map(c => {
                            const {previous, current} = c.nbEmployeesActiveVariation;
                            const variation = current.nb - previous.nb;
                            const getTooltipLine = ({from, to, nb, at}) => `Nb. max d'employés actifs entre le ${moment.unix(from).format("DD/MM/YYYY")} et le ${moment.unix(to).format("DD/MM/YYYY")} : ${nb} le ${moment.unix(at).format("DD/MM/YYYY")}`;
                            return [
                              <Table.Row key={`stats_${c.id}`}>
                                <Table.Cell>{c.name}</Table.Cell>
                                <Table.Cell>{c.code}</Table.Cell>
                                <Table.Cell>{moment.unix(c.subscriptionStart).format("DD/MM/YYYY")}</Table.Cell>
                                <Table.Cell>
                                  <Label key={`${c.id}_nbE`} circular color='teal'>{c.nbEmployees}</Label>
                                  <Label basic key={`${c.id}_nbEA`} circular color='teal'>{c.nbEmployeesActive}</Label>
                                  <Label data-tooltip={`${getTooltipLine(previous)}\n${getTooltipLine(current)}`}
                                    data-position="bottom left" basic key={`${c.id}_nbEV`} circular color={variation === 0 ? 'grey' : variation > 0 ? 'red' : 'green'}>
                                    {variation > 0 ? '+' : ''}{variation}
                                  </Label>
                                  {c.shouldBeBilled !== 0 ? <Label data-tooltip={`Ce client est à facturer ${c.shouldBeBilled === 1 ? 'ce mois ci' : c.shouldBeBilled === 2 ? 'le mois prochain' : 'dans deux mois'}\nDate du jour : ${moment().format("DD/MM/YYYY")}\nDate de souscription : ${moment.unix(c.subscriptionStart).format("DD/MM/YYYY")}\n${getTooltipLine(current)}`} data-position="bottom left" basic key={`${c.id}_billable`} circular color='red'><Icon name='euro sign' color='red' />! {current.nb}</Label> : ''}
                                </Table.Cell>
                                <Table.Cell><Label key={`${c.id}_nbRPIH`} circular color='yellow'>{c.nbRpiHardware}</Label><Label key={`${c.id}_nbRPIH_RENT`} basic circular color='yellow'>{c.nbRpiHardwareRent}</Label></Table.Cell>
                                <Table.Cell><Label key={`${c.id}_nbPHONEH`} circular color='green'>{c.nbPhoneHardware}</Label><Label key={`${c.id}_nbPHONEH_RENT`} basic circular color='green'>{c.nbPhoneHardwareRent}</Label></Table.Cell>
                                <Table.Cell><Label circular color='pink'>{c.phoneSoftware}</Label></Table.Cell>
                                <Table.Cell><Label circular color='blue'>{c.nbTraccarHardware}</Label></Table.Cell>
                                <Table.Cell><Label key={`${c.id}_nbP`} circular color='purple'>{c.nbPointages}</Label><Label key={`${c.id}_nbPR`} basic circular color='purple'>{c.nbRawPointages}</Label></Table.Cell>
                                <Table.Cell>
                                  <span
                                    data-position="bottom right"
                                    data-tooltip={c.type === 'ACTIVE' ? 'Actif' : c.type === 'DEMO' ? 'Démo' : c.type === 'LOCKED' ? 'Verrouillé' : c.type === 'TERMINATED' ? 'Résilié' : 'Inconnu'}>
                                    <Icon
                                      name={c.type === 'ACTIVE' ? 'check' : c.type === 'DEMO' ? 'cloud' : c.type === 'LOCKED' ? 'lock' : c.type === 'TERMINATED' ? 'delete' : 'question'}
                                      color={c.type === 'ACTIVE' ? 'green' : c.type === 'DEMO' ? 'blue' : c.type === 'LOCKED' ? 'red' : c.type === 'TERMINATED' ? 'red' : 'orange'}
                                      />
                                  </span>
                                </Table.Cell>
                                <Table.Cell>
                                  <span
                                    data-position="bottom right"
                                    data-tooltip={`${c.isUsingHisAccount ? "Oui" : "Non"}${c.lastPointageTS !== null && c.lastPointageTS !== undefined ? ` (dernier pointage : ${moment.unix(c.lastPointageTS).format("DD/MM/YYYY HH:mm")})` : ''}`}>
                                    <Icon
                                      name={c.isUsingHisAccount ? 'check' : 'delete'}
                                      color={c.isUsingHisAccount ? 'green' : 'orange'}
                                      />
                                  </span>
                                </Table.Cell>
                                <Table.Cell><Label color='black'>{this.state.monthPriceAsPercent === true ? ((c.totalPerMonth / globalTotal) * 100.0).toFixed(2) : c.totalPerMonth.toFixed(2)} {moneySuffix}</Label></Table.Cell>
                                <Table.Cell><span data-position="bottom right" data-tooltip="Afficher les historiques de données"><Button icon onClick={this.openCustomerDetail(c).bind(this)} ><Icon name='chart bar' /></Button></span></Table.Cell>
                              </Table.Row>,
                              this.state.displayNotes ?
                              <Table.Row key={`notes_${c.id}`}>
                                <Table.Cell style={{textDecoration: 'underline'}}>Notes</Table.Cell>
                                <Table.Cell textAlign='left' colSpan='9'>{c.notes}</Table.Cell>
                                <Table.Cell></Table.Cell>
                              </Table.Row>
                              : null
                            ].flat().filter(x => x !== null);
                          })
                        }
                      </Table.Body>
                    </Table>
              }
              </Grid.Row>
            </Grid>
          }
        </Container>
    );
  }
}

export default Dashboard;
